<svelte:options immutable />

<script lang="ts" context="module">
    export type IconName =
        | 'x'
        | 'at-sign'
        | 'eye'
        | 'eye-off'
        | 'key'
        | 'volume-2'
        | 'play'
        | 'pause'
        | 'skip-forward'
        | 'user-check'
        | 'lock'
        | 'check'
        | 'plus'
        | 'trash'
        | 'arrow-right'
        | 'arrow-up'
        | 'arrow-down'
        | 'mic'
        | 'loader'
        | 'settings'
        | 'square'
        | 'chevron-down'
        | 'chevron-right'
        | 'repeat'
        | 'refresh-cw';
</script>

<script lang="ts">
    import featherSvg from '$lib/assets/feather.svg';

    export let icon: IconName;
    export let size = '24';
    export let color = 'var(--icon-color, currentColor)';
    export let stroke = '2';
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<svg
    width={size}
    height={size}
    fill="none"
    stroke={color}
    stroke-width={stroke}
    stroke-linecap="round"
    stroke-linejoin="round"
    on:click
    {...$$restProps}
>
    <use href={`${featherSvg}#${icon}`} />
</svg>
